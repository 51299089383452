<template>
  <div class="wrapper">
    <GlobalHeader />
    <div :class="s.contents">
      <div><router-view /></div>
    </div>
    <GlobalFooter />
    <LoadingsModal />
    <ContentsModal />
    <MessagesModal />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  GlobalHeader,
  GlobalFooter,
} from '@/components/layouts';
import { ContentsModal, MessagesModal, LoadingsModal } from '@/components/modal';

export default {
  name: 'App',
  components: {
    GlobalHeader,
    GlobalFooter,
    ContentsModal,
    MessagesModal,
    LoadingsModal,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['user', 'modal']),
  },
  created() {
    // ログインチェック
    // this.$store.dispatch('user/login/check');
    // backend共通マスターデータ取得
    // this.$store.dispatch('helper/getMaster');
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" module="s">
.contents {
  min-height: 100vh;
  padding-top: var(--header-height);
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  @include mdView {
    padding-top: var(--md-header-height)
  }
}
</style>

<style lang="scss">
@import './assets/scss/_mq.scss';
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_fonts.scss';
@import './assets/scss/modal.scss';
@import '@/assets/scss/style.scss';
</style>

<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
