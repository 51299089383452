<template>
  <div :class="s.wrap">
    <div :class="s.rideshare" class="onlyPc">
      <img src="/img/logos/rideshare.svg" alt="">
    </div>
    <div :class="s.sp_container">
      <img src="/img/logos/app-icon.svg" alt="">
    </div>
    <div :class="s.rideshare" class="onlySp">
      <img src="/img/logos/rideshare.svg" alt="">
    </div>
    <div :class="s.qr">
      <img src="/img/QR/line_qr.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'key-visual',
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: stretch;
}
.rideshare {
  flex: 1;
  padding-top: 149px;
  > img {
    display: block;
    margin: 0 auto;
    width: 63%;
  }
}
.sp_container {
  width: 390px;
  padding: 163px 0 calc(257px + (100vw * (215 / 1440 / 2)));
  box-shadow: rgba(#0F6B94, 0.22) 0 0 44px 0;
  border-radius: 40px 40px 0 0;
  text-align: center;
  > img {
    width: 50%;
  }
}
.qr {
  flex: 1;
  text-align: center;
  padding-top: 167px;
  > img {
    width: 33%;
    min-width: 150px;
    border-radius: 12px;
    border: 8px solid var(--line-green);
  }
}

@include mdView {
  .wrap {
    flex-direction: column;
    align-items: center;
  }

  .sp_container {
    box-shadow: none;
    margin-top: 108px;
    padding: 0;
    width: 50%;
    > img {
      width: 100%;
    }
  }

  .rideshare {
    margin-top: 100px;
    padding: 0;
    > img {
      width: 100%;
    }
  }

  .qr {
    margin-top: 100px;
    padding: 0;
    padding-bottom: calc(180px + (100vw * (215 / 1440 / 2)));
    > img {
      width: 250px;
    }
  }
}
@include smView {
  .sp_container {
    width: 60%;
  }
  .rideshare {
    margin-top: 66px;
    width: 60%;
  }
  .qr {
    margin-top: 66px;
    padding-bottom: calc(120px + (100vw * (215 / 1440 / 2)));
    > img {
      width: 40%;
    }
  }
}
</style>
