<template>
  <div :class="s.wrapper">
    <div class="position-relative">
      <!-- menu items -->
      <div :class="[s.content, isOpen ? s.isOpen : '']">
        <ul>
          <li
            v-for="(menu, i) in menuItems"
            :key="i">
            <router-link :to="`/${menu.link}`">{{ menu.label }}</router-link>
          </li>
        </ul>
      </div>
      <!-- button -->
      <div
        :class="[s.btn, isOpen ? s.isOpen : '']"
        @click="isOpen = !isOpen">
        <div class="position-relative">
          <span/><span/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'menu-drawer',
  props: {
    menuItems: {
      type: Array,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style lang="scss" module="s">
.wrapper {
  position: fixed;
  top: 0; right: 0;
  width: 100%;
}

.btn {
  position: fixed;
  top: 0;
  right: 0;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  > * {
    > span {
      display: block;
      position: absolute;
      top: 50%; left: 50%;
      width: 24px;
      height: 6px;
      background-color: var(--label-primary);
      border-radius: 12px;
      transform-origin: center;
      transform: translate(-50%, -50%);
      transition: all .3s ease-in-out;
      &:nth-child(1) {
        transform: translate(-50%, calc(-50% - 5px)) rotate(0deg);
      }
      &:nth-child(2) {
        transform: translate(-50%, calc(-50% + 5px)) rotate(0deg);
      }
    }
  }

  &.isOpen {
    > * {
      > span {
        &:nth-child(1) {
          transform: translate(-50%, -50%) rotate(225deg);
        }
        &:nth-child(2) {
          transform: translate(-50%, -50%) rotate(-225deg);
        }
      }
    }
  }
}

.content {
  backdrop-filter: blur(34px);
  width: 100%;
  padding: var(--md-header-height) 80px 80px;
  transform: translateY(-100%);
  transition: all .5s ease-in-out;

  &.isOpen {
    transform: translateY(0);
  }
  > ul {
    padding-top: 40px;
    > li {
      font-size: 24px;
      &:not(:first-child) {
        margin-top: 40px;
      }
    }
  }
}
</style>
