<template>
  <footer :class="s.wrap">
    <img :class="s.top_img" src="/img/parts/footer_top_img.svg" alt="">
    <div :class="s.content">
      ©️ 2024 MITT
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'GlobalFooter',
  mixins: [cf],
  components: {},
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" module="s">
.wrap {
  position: relative;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: var(--footer-height);
  background-color: #ffffff;
  border-top: 1px solid var(--border-gray);
}

.top_img {
  z-index: 1;
  display: block;
  position: absolute;
  bottom: 100%;
  width: 100%;
  transform: translateY(28%);
}
.content {
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--label-primary);
  height: 100%;
}
</style>
