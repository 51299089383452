<template>
  <transition name="fade" mode="out-in">
    <div class="modal messages" v-if="isShow">
      <div class="modal__bg" v-on:click="hideModal" :class="$style.wrap"></div>

      <div class="modal__content" v-bind:class="$style.modal">
        <!-- modalNameに応じて表示するコンポーネントを変化 -->
        <component
          v-bind:is="messages.modalName"
          v-bind:data="messages.data" />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
// モーダルコンポーネントの登録

export default {
  name: 'MessagesModal',
  components: {
  },
  computed: {
    ...mapState('modal', ['messages']),
    isShow() {
      return this.messages.modalName !== '';
    },
  },
  methods: {
    hideModal(args = null) {
      this.$store.dispatch('modal/messages/hideModal', args, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .4);
}
.modal {
  padding: 40px;
  width: 80%;
  max-width: 600px;
  max-height: 70%;
  position: fixed;
  top: calc(50% + var(--header-height) / 2);
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  overflow: scroll;
}
</style>
