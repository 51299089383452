<template>
  <header :class="s.wrap">
    <MenuDrawer
      class="onlySp"
      :class="s.menu"
      :menuItems="menuItems"/>

    <div :class="s.contents">
      <router-link to='/'>
        <img
          :class="s.logo"
          src="/img/logos/logo_text.png"
          alt="Mitt logo">
      </router-link>
      <ul :class="s.menuItems" class="onlyPc">
        <li
          v-for="(menu, i) in menuItems"
          :key="i">
          <router-link
            :class="[menu.isButton ? s.menu_button : '']"
            :to="`/${menu.link}`">{{ menu.label }}</router-link>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import MenuDrawer from './MenuDrawer.vue';

export default {
  name: 'GlobalHeader',
  components: {
    MenuDrawer,
  },
  data() {
    return {
      menuItems: [
        {
          label: '特徴',
          link: '#features',
          isButton: false,
        },
        {
          label: '使い方',
          link: '#usage',
          isButton: false,
        },
        {
          label: '導入｜事業者様向け',
          link: '#introduction',
          isButton: true,
        },
      ],
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
  },
  methods: {},
};
</script>

<style lang="scss" module="s">
.wrap {
  position: fixed;
  top: 0;
  width: 100vw;
  color: var(--logo-primary);
  background-color: var(--primary-gray);
  z-index: 10;
  a {
    color: var(--logo-primary);
  }
}

.menu {
  z-index: 1;
}
.contents {
  pointer-events: none;
  > * { pointer-events: auto; }
  position: relative;
  z-index: 2;
  height: var(--header-height);
  padding: 40px 40px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 32px;
  width: auto;
}

.menuItems {
  display: flex;
  > li {
    &:not(:first-child) {
      margin-left: 48px;
    }
  }
  .menu_button {
    padding: 8px 24px;
    background-color: var(--label-primary);
    color: var(--label-inverse);
    border-radius: 150px;
  }
}

@include mdView {
  .contents {
    height: var(--md-header-height);
    padding: 40px 20px 20px;
  }
}
</style>
