<template>
  <ul :style="style" :class="s.list">
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'vertical-list',
  props: {
    spaceY: {
      type: Number,
    },
    mdSpaceY: {
      type: Number,
    },
  },
  data() {},
  computed: {
    style() {
      return {
        '--space-Y': `${this.spaceY || 24}px`,
        '--md-space-Y': `${this.mdSpaceY || 24}px`,
      };
    },
  },
};
</script>

<style lang="scss" module="s">
.list {
  > li {
    &:not(:first-child) {
      margin-top: var(--space-Y);
      @include mdView { margin-top: var(--md-space-Y); }
    }
  }
}
</style>

