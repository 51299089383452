<template>
  <div :style="style" :class="s.wrap">
    <div :class="s.title_wrap">
      <div :class="s.title">{{ title }}</div>
      <div :class="s.sub_title">{{ subTitle }}</div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'section-container',
  props: {
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    color: {
      type: String,
    },
    paddingTop: {
      type: Number,
    },
    mdPaddingTop: {
      type: Number,
    },
    paddingBottom: {
      type: Number,
    },
    mdPaddingBottom: {
      type: Number,
    },
  },
  data() {},
  computed: {
    style() {
      return {
        '--color': this.color || 'var(--label-primary)',
        '--padding-top': `${this.paddingTop || this.paddingTop === 0 ? this.paddingTop : 160}px`,
        '--md-padding-top': `${this.mdPaddingTop || this.mdPaddingTop === 0 ? this.mdPaddingTop : 160}px`,
        '--padding-bottom': `${this.paddingBottom || this.paddingBottom === 0 ? this.paddingBottom : 160}px`,
        '--md-padding-bottom': `${this.mdPaddingBottom || this.mdPaddingBottom === 0 ? this.mdPaddingBottom : 160}px`,
      };
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
}
.title_wrap {
  color: var(--color);
  text-align: center;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 80px;
}
.title {
  font-size: 72px;
  letter-spacing: calc(72px * 0.05);
}
.sub_title {
  font-size: 28px;
  letter-spacing: calc(28px * 0.1);
  margin-top: 20px;
}

@include mdView {
  .wrap {
    padding-top: var(--md-padding-top);
    padding-bottom: var(--md-padding-bottom);
  }
  .title_wrap {
    margin-bottom: 40px;
  }
  .title {
    font-size: 36px;
    letter-spacing: calc(36px * 0.05);
  }
  .sub_title {
    font-size: 16px;
    letter-spacing: calc(16px * 0.1);
    margin-top: 12px;
  }
}
</style>

